import BigNumber from 'bignumber.js'
import { useMemo } from 'react'

export const PRECISION_0 = 0
export const precision12 = new BigNumber(10).pow(12)
export const precision18 = new BigNumber(10).pow(18)

export const toPrecision12 = (amount?: string | BigNumber) =>
  amount && new BigNumber(amount).multipliedBy(precision12).toFixed(PRECISION_0)
export const useToPrecision12 = (amount?: string | BigNumber) =>
  useMemo(() => toPrecision12(amount), [amount])

export const useToPrecision18 = (amount?: string | BigNumber) =>
  useMemo(
    () =>
      amount &&
      new BigNumber(amount).multipliedBy(precision18).toFixed(PRECISION_0),
    [amount]
  )
